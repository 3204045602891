import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import MoneyOff from "@material-ui/icons/MoneyOff";
import DescriptionIcon from '@material-ui/icons/Description';
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import InfoArea from "../../../components/InfoArea/InfoArea.js";

import styles from "../../../assets/jss/material-kit-react/views/aboutPageSections/informationStyle.js";
const useStyles = makeStyles(styles);

export default function WhySection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Mengapa berbelanja di Plasgos?</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Aman dan Terpercaya"
              titleStyle={{fontSize:18}}
              descriptionStyle={{fontSize:18, lineHeight: '1.2'}}
              description="Dengan menggunakan rekening bersama, maka calon pembeli merasa aman, karena uang pembeli hanya akan diteruskan ke Penjual jika Pembeli sudah menerima barang sesuai pesanan"
              icon={VerifiedUser}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Gratis & Bebas Komisi"
              titleStyle={{fontSize:18}}
              descriptionStyle={{fontSize:18, lineHeight: '1.2'}}
              description="Sudah Punya Toko Online tapi penjualan tidak ada peningkatan? Kami akan menghubungkan Anda dengan seluruh Suplier / Distributor di Indonesia dan Tentunya GRATIS."
              icon={MoneyOff}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Transparan dan Effisien"
              titleStyle={{fontSize:18}}
              descriptionStyle={{fontSize:18, lineHeight: '1.2'}}
              description="Anda tidak perlu melakukan kunjungan Pabrik ke Perusahaan yang bersangkutan sehingga Anda dapat menghemat waktu. Dan Transaksi Anda pun sangat transparant.."
              icon={DescriptionIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
