import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
// @material-ui/icons
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

import styles from "../../../assets/jss/material-kit-react/views/aboutPageSections/informationStyle.js";

const useStyles = makeStyles(styles);

export default function InformationSection() {
  const classes = useStyles();
  return (
    <div className={classes.section} style={{backgroundColor: 'rgb(241, 244, 247)', paddingRight:20, paddingLeft: 20}}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Perusahaan Teknologi dengan visi Bisnis ke Bisnis (B2B)</h2>
          <div className={classes.description}>
            <p style={{textAlign:'left', fontSize:18}}>Pernah kamu <b>mencari produk</b> dari sebuah produsen tetapi <b>tidak berhasil</b> ?</p>
            <p style={{textAlign:'left', fontSize:18}}>Pernah mencari produk dari sebuah perusahaan tetapi ketemunya para Reseller dengan harga yang <b>lebih Mahal</b> ?</p>
            <p style={{textAlign:'left', fontSize:18}}>Mau beli produk tetapi <b>tidak yakin terhadap perusahaannnya</b>, sehingga harus menempuh jarak yang begitu jauh untuk mendapatkan produk yang diinginkan (COD) ?</p>
          </div>
          <div><Button variant="outlined" href="https://www.plasgos.co.id/register" size="large">Daftar Sekarang, Gratis</Button></div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
