import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
// @material-ui/icons

// core components
import Header from "../../components/Header/HeaderAbout.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";

import styles from "../../assets/jss/material-kit-react/views/aboutPage.js";

// Sections for this page
import InformationSection from "./Sections/InformationSection.js";
import GoalSection from "./Sections/GoalSection.js";
import VisionSection from "./Sections/VisionSection.js";
import WhySection from "./Sections/WhySection.js";
import AddressSection from "./Sections/AddressSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax image={require("../../assets/img/animate-building.png")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
            {
              /*
              <div className={classes.brand}>
                <h1 className={classes.title}>Memberda</h1>
                <h3 className={classes.subtitle}>
                  A Badass Material-UI Kit based on Material Design.
                </h3>
              </div>
              */
            }
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <InformationSection />
          <Divider />
          <GoalSection />
          <Divider />
          <VisionSection />
          <WhySection />
          <AddressSection/>
        </div>
      </div>
      <Footer />
    </div>
  );
}
